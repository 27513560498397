// 将所有请求全部封装在外部，为了方便复用和统一的维护
import request from '_utils/request'

// 登陆
const doLogin = (params = {}) => request.get('/login', { params })
// 获取验证码
const ValidCode = (params = {}) => request.get('/captcha', { params })
// ApiToken
const GetApiToken = (params = {}) => request.get('/token')
// 退出登录
const logOut = (params = {}) => request.post('/users/logout', params)
// ****************************
// 域名列表
const dnsList = (params = {}) => request.get('/domains', { params })
// 添加域名
const ADDDomains = (params = {}) => request.post('/domains', params)
// 批量添加域名
const ADDBatchDomains = (params = {}) => request.post('/domains/batchStore', params)
// 修改域名及单个修改状态
const EITDomains = (params = {}) => request.put('/domains/' + params.id, params)
// 域名详情
const EITDetails = (params = {}) => request.get('/domains/' + params.id)
// 删除域名
const DELDomains = (params = {}) => request.delete('/domains/' + params.id)
// 批量删除域名
const batchDestroy = (params) => request.delete('/domains/batchDestroy', { data: params })
// 申请证书
const batchSetHttps = (params = {}) => request.put('/domains/batchUpdate', params)
// 更换跳转地址
const batchToDomain = (params = {}) => request.put('/domains/batchUpdateToDomain', params)
// 下载证书
const updatedHTTPS = (params = { }) => request.post('/domains/certDownload?id=' + params.id, params)
// 域名解析
const AnalyzeDomains = (params = {}) => request.post('/domains/dns' , params)
// 更换跳转地址2
const batchToDomain2 = (params = {}) => request.put('/domains/updateJumpAddress', params)

// 更换跳转地址 （1电脑端、2移动端）
const jumpAddressUpdate  = (params = {}) => request.put('/domains/jumpAddressUpdate', params)
// 模糊查询域名列表
const matchDomainAll = (params = {}) => request.get('/domains/matchDomainAll?match_domain=' + params.match_domain+'&group_id='+params.group_id)
// 模糊查询 电脑端 移动端列表
const jumpAddressGroup = (params = {}) => request.post('/domains/jumpAddressGroup' , params)
// 添加域名 查找库中是否存在当前域名提示接口
const domainRepeat  = (params = {}) => request.post('/domains/domainRepeat' , params)
// *******************************
// 首页
const Homes = (params = {}) => request.get('/homes', { params })
// 列表
const Articles = (params = {}) => request.get('/articles', { params })
// 修改
const EITArticles = (params = {}) => request.put('/articles/' + params.id, params)
// **********************************
// 分组列表
const groupsList = (params = {}) => request.get('/groups', { params })
// 添加分组
const ADDGroup = (params = {}) => request.post('/groups', params)
// 删除分组
const delGroup = (params = {}) => request.delete('/groups/' + params.id)
// 修改分组
const eitGroup = (params = {}) => request.put('/groups/' + params.id, params)
// 替换跳转地址
const replaceUrl = (params = {}) => request.put('/domains/replaceUrl', params)

// ************************************
// 用户列表
const userList = (params = {}) => request.get('/users', { params })
// 用户详情
const userDetails = (params = {}) => request.get('/users/' + params.id)
// 添加用户
const ADDUser = (params = {}) => request.post('/users', params)
// 删除用户
const delUser = (params = {}) => request.delete('/users/' + params.id)
// 修改用户
const eitUser = (params = {}) => request.put('users/' + params.id, params)

// ************************************
// 节点列表
const permissionsList = (params = {}) => request.get('/permissions', { params })
// 添加节点
const ADDPermissions = (params = {}) => request.post('/permissions', params)
// 删除节点
const delPermissions = (params = {}) => request.delete('/permissions/' + params.id)
// 修改节点
const eitPermissions = (params = {}) => request.put('/permissions/' + params.id, params)

// 端口列表
const portsList = (params = {}) => request.get('/nodePorts', { params })
// 添加端口
const ADDPort = (params = {}) => request.post('/nodePorts', params)
// 删除端口
const delPort = (params = {}) => request.delete('/nodePorts/' + params.port,{params})
// ************************************
// 角色列表
const rolesList = (params = {}) => request.get('/roles', { params })
// 添加角色
const ADDRoles = (params = {}) => request.post('/roles', params)
// 删除角色
const delRoles = (params = {}) => request.delete('/roles/' + params.id)
// 修改角色
const eitRoles = (params = {}) => request.put('/roles/' + params.id, params)
// 角色详情
const roleDetails = (params = {}) => request.get('/roles/' + params.id)
// ************************************
// 节点列表
const NodesList = (params = {}) => request.get('/nodes', { params })
// 添加节点
const ADDNodes = (params = {}) => request.post('/nodes', params)
// 删除节点
const delNodes = (params = {}) => request.delete('/nodes/' + params.id)
// 修改节点
const eitNodes = (params = {}) => request.put('/nodes/' + params.id, params)

// **********************************
// DNS列表
const DNSList = (params = {}) => request.get('/dns', { params })
// 添加DNS
const ADDDNS = (params = {}) => request.post('/dns', params)
// 删除DNS
const delDNS = (params = {}) => request.delete('/dns/' + params.id)
// 修改DNS
const eitDNS = (params = {}) => request.put('/dns/' + params.id, params)
// DNS详情
const DNSDetails = (params = {}) => request.get('/dns/' + params.id)

// **********************************
// 日志列表
const journalList = (params = {}) => request.get('/apiLogs', { params })
// 删除日志
const delJournal = (params = {}) => request.delete('/apiLogs/' + params.id)
// 批量删除日志
const batchJournal = (params) => request.delete('/apiLogs/batchDestroy', { data: params })
// **********************************
// IP列表
const IPList = (params = {}) => request.get('/getIps', { params })
// **********************************
// 域名备份
const backupsList = (params = {}) => request.get('/domain/csvLists', { params })
// 备份下载
const backupsDownload = (params = {}) => request.get('/file/downloads?path='+params.item)
// **********************************
// 操作日志
const domainLogs = (params = {}) => request.get('/domainLogs', { params })
export {
  backupsDownload,
  backupsList,
  doLogin,
  userDetails,
  roleDetails,
  dnsList,
  groupsList,
  userList,
  ADDGroup,
  ADDDomains,
  EITDomains,
  DELDomains,
  ADDBatchDomains,
  batchSetHttps,
  EITDetails,
  batchDestroy,
  Homes,
  delGroup,
  // replaceUrl,
  eitGroup,
  delUser,
  eitUser,
  ADDUser,
  permissionsList,
  ADDPermissions,
  delPermissions,
  eitPermissions,
  eitRoles,
  delRoles,
  ADDRoles,
  rolesList,
  logOut,
  // batchToDomain,
  Articles,
  EITArticles,
  NodesList,
  ADDNodes,
  delNodes,
  eitNodes,
  ValidCode,
  updatedHTTPS,
  DNSList,
  ADDDNS,
  delDNS,
  eitDNS,
  DNSDetails,
  journalList,
  delJournal,
  batchJournal,
  IPList,
  portsList,
  ADDPort,
  delPort,
  AnalyzeDomains,
  GetApiToken,
  // batchToDomain2,
  matchDomainAll,
  jumpAddressGroup,
  jumpAddressUpdate,
  domainRepeat,
  domainLogs
}
