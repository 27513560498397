export default {
  namespaced: true,
  // vuex存储公共状态的文件
  state: {
    menuDrawer: false, // 小屏模式侧边栏是否显示
    // 侧边栏导航是否关闭，默认展开
    meunCollapse: false,
    // 路径名称
    // 获取sessionStorage中的数据
    menuNames: sessionStorage.getItem('menuNames') ? JSON.parse(sessionStorage.getItem('menuNames')) : [],
    // 历史记录
    // 获取sessionStorage中的数据
    navTab: sessionStorage.getItem('navTab') ? JSON.parse(sessionStorage.getItem('navTab')) : [],
    // 所有侧边导航的数据
    navs: [
      {
        label: '通知公告',
        path: '/dashBoard',
        icon: 'el-icon-s-home',
        roles: ['admin', 'superAdmin', 'general']
      },
      {
        label: '域名管理',
        path: '/itemLists',
        icon: 'el-icon-odometer',
        roles: ['admin', 'superAdmin', 'general']
      },
      {
        label: '分组管理',
        path: '/groupLists',
        icon: 'el-icon-s-unfold',
        roles: ['admin', 'superAdmin', 'general']
      },
      // {
      //   label: '商品',
      //   path: '',
      //   icon: 'el-icon-goods',
      //   roles: ['admin', 'a', 'b', 'superAdmin'],
      //   children: [
      //     {
      //       label: '商品管理',
      //       path: '/itemLists'
      //     },
      //     {
      //       label: '分类管理',
      //       path: '/groupLists'
      //     }
      //   ]
      // },
      {
        label: '用户管理',
        roles: ['admin', 'superAdmin'],
        path: '/SetUser',
        icon: 'el-icon-user'
      },
      {
        // roles: '*',
        label: '权限管理',
        path: '/SetTing',
        icon: 'el-icon-setting',
        roles: ['superAdmin']
      },
      {
        roles: ['superAdmin'],
        label: '角色管理',
        path: '/SetRole',
        icon: 'el-icon-view'
      },
      {
        roles: ['superAdmin'],
        label: '节点管理',
        path: '/nodelist',
        icon: 'el-icon-share'
      },
      {
        roles: ['admin', 'superAdmin', 'general'],
        label: 'DNS管理',
        path: '/certificate',
        icon: 'el-icon-s-check'
      },
     
      {
        roles: ['superAdmin'],
        label: '日志管理',
        path: '/journal',
        icon: 'el-icon-s-order'
      },
      {
        label: '工具箱',
        path: '',
        icon: 'el-icon-goods',
        roles: ['superAdmin'],
        children: [
          {
            label: 'ip地址段',
            path: '/ip'
          },
          {
            label: '域名备份',
            path: '/backups'
          },
        ]
      },
      {
        roles: ['superAdmin'],
        label: '监控',
        path: '/monitoring',
        icon: 'el-icon-s-data'
      },
      {
        roles: ['admin', 'superAdmin', 'general'],
        label: '操作日志',
        path: '/history',
        icon: 'el-icon-s-management'
      },
    ]
  },
  getters: {
    authNavs: (state) => (role) => { // 使用闭包接收 role 参数
      // 查找state中的navs的roles中有角色名，让有角色名的路由显示
      return state.navs.filter(nav => nav.roles.includes(role))
    }
  },
  mutations: {
    // 展开侧边栏
    OPEN_MENU (state, status) {
      if (status) {
        state.meunCollapse = status
      } else {
        state.meunCollapse = !state.meunCollapse
      }
    },
    // 抽屉
    OPEN_DRAWER (state, status) {
      if (status) {
        state.menuDrawer = status
      } else {
        state.menuDrawer = !state.menuDrawer
      }
    },
    // 设置头部面包蟹导航
    SET_MENU_INFO (state, menuNames) {
      state.menuNames = menuNames
      // 存储到sessionStorage中
      sessionStorage.setItem('menuNames', JSON.stringify(state.menuNames))
    },
    // 清空头部的面包蟹导航
    CLEAR_MENUNAMES (state) {
      state.menuNames = []
      // 存储到sessionStorage中
      sessionStorage.setItem('menuNames', JSON.stringify(state.menuNames))
    },
    // 添加头部的历史记录导航
    ADD_TABS (state, tab) {
      // 添加时判断数组中是否已经存在
      const index = state.navTab.findIndex(navTabs => tab.path === navTabs.path)
      if (index !== -1) {
        return false
      }
      state.navTab.push(tab)
      // 存储到sessionStorage中
      sessionStorage.setItem('navTab', JSON.stringify(state.navTab))
    },
    // 删除头部的历史记录导航
    DELETE_TABS (state, index) {
      state.navTab.splice(index, 1)
      // 存储到sessionStorage中
      sessionStorage.setItem('navTab', JSON.stringify(state.navTab))
    },
    // 退出清空历史记录
    RESET_STATE (state) {
      state.menuDrawer = false // 小屏模式侧边栏是否显示
      // 侧边栏导航是否关闭，默认展开
      state.meunCollapse = false
      // 路径名称
      state.menuNames = []
      // 历史记录
      state.navTab = []
    }
  }
}
