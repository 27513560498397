<template>
  <transition name="ani1">
    <div class="aside">
    <el-menu
      :default-active="$store.state.menu.menuNames[0]?$store.state.menu.menuNames[0]:'通知公告'"
        @select="choseMenu"
        active-text-color="#409eff"
        text-color="#bdc2cb"
        :collapse-transition="true"
        :collapse="$store.state.menu.meunCollapse"
        >
        <div v-for="nav in filteredNavs" :key="nav.label">
          <!-- 首页-->
          <el-menu-item
            :index="nav.label"
            @click="switchNav(nav.path, nav.label)"
            v-if="!nav.children"
          >
             <i :class="nav.icon"></i>
            <span slot="title">{{ nav.label }}</span>
          </el-menu-item>
          <!-- 商品 商品管理 分类管理 -->
          <el-submenu :index="nav.label" v-if="nav.children" >
            <template slot="title">
              <i :class="nav.icon"></i>
              <span :style="{display:$store.state.menu.meunCollapse== true?'none':'',marginLeft:'5px'}" >{{ nav.label }}</span>
            </template>
              <el-menu-item
                v-for="subNav in nav.children"
                :key="subNav.label"
                :index="subNav.label"
                @click="switchNav(subNav.path, subNav.label)"
                >{{ subNav.label }}</el-menu-item
              >
          </el-submenu>
          <!-- 底部 -->
        </div>
      </el-menu>
    </div>
  </transition>
</template>
<script>
import { isLogin } from '_utils'
export default {
  data () {
    return {
      meunCollapse: false,
      menu: [],
      windowWidth:''
    }
  },
  watch: {
    isLogin (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue == true) {
          window.location.reload()
        }
      }
    }
  },
  computed: {
    filteredNavs () {
      const role = localStorage.getItem('role') || ''
      return this.$store.getters['menu/authNavs'](role) // 将角色作为参数传递给 getter
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    isLogin,
    // 实时获取浏览器宽度
    handleResize () {
      this.windowWidth = window.innerWidth
    },
     // 点击跳转对应面包屑
    choseMenu (name, PathNames) {
    
      if (this.windowWidth <= 992) {
        this.$store.commit('menu/OPEN_DRAWER')
      }
      this.$store.commit('menu/SET_MENU_INFO', PathNames)
      this.menu = PathNames
    },
    // 点击侧边栏显示对应内容
    switchNav (path, navName) {
      this.$router.push(path) // 跳转到对应路由
      if (path === '/dashBoard') {
        return false
      }
      this.$store.commit('menu/ADD_TABS', {
        label: navName,
        path,
        menuNames: this.menu
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.aside {
  text-align: left;
  .el-menu {
    border-right: 0px;
    background-color: #18212c;
  }
  ::v-deep .el-submenu__title {
    &:hover,
    &:focus {
      color: white;
      background-color: #18212c;
      i,
      span {
        color: white;
      }
    }
    i,
    span {
      color: #bdc2cb;
    }
  }
    .el-menu-item {
      color: #bbbb;
    }
}
::v-deep .el-menu-item:focus, ::v-deep .el-menu-item:hover{
background-color: #1e1e1e;
color: rgb(64, 158, 255) !important;
}

::v-deep .el-menu{
  background-color: #18212c
}

</style>
